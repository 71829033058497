/* globals document window */

import * as referrerStorage from 'shared/utils/referrerStorage';

import setupPageviewTracking from './setupPageviewTracking';
import setupSimpleEventTracking from './setupSimpleEventTracking';
import setupImpressionTracking from './setupImpressionTracking';
import setupImpressionDurationTracking from './setupImpressionDurationTracking';

async function main() {
  if (window.top !== window.self) return;

  referrerStorage.unsetStickyReferrerIfExpired();

  const storedStickyReferrer = referrerStorage.getStickyReferrer();
  const isReferrerExternal = !(document.referrer === '' || document.referrer.includes(window.location.hostname));

  referrerStorage.setEntryPoint(window.location.href);

  if (!storedStickyReferrer || isReferrerExternal) {
    const externalReferrer = isReferrerExternal ? document.referrer : undefined;

    referrerStorage.setStickyReferrer(externalReferrer);
  }

  if (
    !window.IntersectionObserver ||
    !window.IntersectionObserverEntry ||
    !('intersectionRatio' in window.IntersectionObserverEntry.prototype)
  ) {
    await import('intersection-observer');
  }

  setupPageviewTracking();
  setupSimpleEventTracking();
  setupImpressionTracking();
  setupImpressionDurationTracking();
}

main();
